<!--
 * @Author: lbh
 * @Date: 2022-12-07 17:20:00
 * @LastEditors: lbh
 * @LastEditTime: 2023-06-08 15:47:37
 * @Description: 協議 - 自動展示, 無需拖拽
-->
<template>
  <div class="agrreement-box px-agrreement-box">
    <!-- <div
      v-text="configs.title"
      class="title"
    ></div> -->
    <div v-html="configs[`${nowL.code}content`] || configs.content
"></div>
  </div>
</template>

<script>
export default {
  name: "selfAgrreement",
  props: {
    configs: {
      default: {
        title: "",
        content: ""
      }
    }
  },
  data () {
    return {
      nowL: {
        code: 'HK',
        name: '繁體'
      }
    }
  },
  created () {
    this.initLang();
    this.$changeLang.set('agreement', this.initLang)
    //粘贴
    document.querySelector('.agrreement-box').addEventListener('paste', (e) => {
      // 防止默认的粘贴事件和停止冒泡
      e.preventDefault();
      e.stopPropagation();
    });
    document.querySelector('.agrreement-box').addEventListener('copy', (e) => {
      // 防止默认的粘贴事件和停止冒泡
      e.preventDefault();
      e.stopPropagation();
    });
  },
  methods: {
    initLang () {
      let language = this.$storage.get('language')
      let now_language = this.$storage.get('now_language')
      if (!now_language) now_language = 'HK';
      for (let i = 0; i < language.length; i += 1) {
        if (language[i].code == now_language) {
          this.nowL = language[i]
          break;
        }
      }
    },
  }
}
</script>

<style lang="less" scoped>
.agrreement-box {
  padding: 30px 360px;
  user-select: none;
  div,
  p,
  span {
    user-select: none;
  }
  .title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
}
@media screen and (max-width: 1000px) {
  .px-agrreement-box {
    .title {
      font-size: 26px;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 500px) {
  .px-agrreement-box {
    padding: 12px 10px;
    .title {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>